import React from 'react';
import { ContentfulNavigationItem } from '../../../../../contentful/content-types/navigationitem';
import { iconMap } from '../../../../common/CFIcons/IconMap';
import { Divider } from '../../../../common/Divider';
import Dropdown from '../../../../common/Dropdown';
import { Button, Div, Li, Span, Ul } from '../../../../common/Elements';
import { Icon } from '../../../../common/Icon';
import { LocaleLink } from '../../../../common/links/LocaleLink';
import Text from '../../../../common/Text/Text';
import { trackNavigationData } from '../../utils/navigation.tracking';

interface Props {
  item: ContentfulNavigationItem;
  hideDivider?: boolean;
}

export const UtilityNavItem: React.FC<Props> = ({ item, hideDivider }) => {
  const NavIcon = item.navIcon ? iconMap[item.navIcon.icon] : null;

  return (
    <React.Fragment key={item.contentfulId}>
      <Dropdown
        styles={{ width: '194px' }}
        renderDropdownContent={() => {
          const navGroups = item.navigationItems;
          const navLinks = item.navigationLinks;
          return (
            <>
              {navGroups?.map((group, index) => (
                <React.Fragment key={group.contentfulId}>
                  <Div>
                    {group.mainLink && (
                      <Div key={group.mainLink.contentfulId} marginBottom={1}>
                        <LocaleLink
                          to={group.mainLink.url}
                          variant="anchor"
                          display="block"
                          className="newNav-link"
                          onClick={() =>
                            trackNavigationData({
                              element: 'link',
                              label: `utilitynav|${item.name}_${group.mainLink.title}:[${group.mainLink.url}]`,
                            })
                          }
                        >
                          <Text
                            hovered={{ fontWeight: 7 }}
                            tag="Span"
                            color="black"
                            variant="body3"
                          >
                            {group.mainLink.title}
                          </Text>
                        </LocaleLink>
                      </Div>
                    )}
                    <Ul className="list" margin={0} padding={0}>
                      {group.navigationLinks?.map((navlink, i, { length }) => (
                        // Do not put a marginBottom on the last LI
                        // This is to prevent excessive whitespace interacting
                        // other elements like <Divider>
                        <Li key={navlink.contentfulId} marginBottom={i === length - 1 ? 0 : 1}>
                          <LocaleLink
                            to={navlink.url}
                            variant="anchor"
                            className="newNav-link"
                            display="block"
                            onClick={() =>
                              trackNavigationData({
                                element: 'link',
                                label: `utilitynav|${item.name}_${navlink.title}:[${navlink.url}]`,
                              })
                            }
                          >
                            <Text
                              hovered={{ fontWeight: 7 }}
                              tag="Span"
                              color="black"
                              variant="body3"
                            >
                              {navlink.title}
                            </Text>
                          </LocaleLink>
                        </Li>
                      ))}
                    </Ul>
                  </Div>
                  {index !== navGroups?.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {navLinks?.length && (
                <Ul className="list" margin={0} padding={0}>
                  {navLinks?.map((navlink) => (
                    <Li key={navlink.contentfulId} marginBottom={1}>
                      <LocaleLink
                        display="block"
                        to={navlink.url}
                        className="newNav-link"
                        variant="anchor"
                        onClick={() =>
                          trackNavigationData({
                            element: 'link',
                            label: `utilitynav|${item.name}_${navlink.title}:[${navlink.url}]`,
                          })
                        }
                      >
                        <Text hovered={{ fontWeight: 7 }} tag="Span" color="black" variant="body3">
                          {navlink.title}
                        </Text>
                      </LocaleLink>
                    </Li>
                  ))}
                </Ul>
              )}
            </>
          );
        }}
        renderToggle={(isOpen, toggle) => (
          <Button
            data-tooltipped=""
            className="pointer newNav-button"
            onClick={() => {
              toggle();
              trackNavigationData({
                element: 'dropdown',
                label: `utilitynav|${item.name}`,
              });
            }}
            role="button"
            type="button"
            backgroundColor="transparent"
            padding={0}
            lineHeight={0}
            border="none"
            tabIndex={0}
          >
            <Text
              tag="Span"
              marginRight={1}
              marginLeft={1}
              display="flex"
              alignItems="center"
              variant="body3-bold"
              color="gray0"
            >
              {NavIcon && <NavIcon size={item.navIcon.size} color={item.navIcon.color} />}
              {!item.showIconOnly && item.name}
              <Span className={`${isOpen ? 'rotate-180' : ''}`} lineHeight={0} marginLeft="4px">
                <Icon type="nav-caret" />
              </Span>
            </Text>
          </Button>
        )}
      />
      {!hideDivider && (
        <Text variant="body3" color="black">
          |
        </Text>
      )}
    </React.Fragment>
  );
};
