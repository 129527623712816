import React, { FC, useState, useEffect } from 'react';
import Media from 'react-media';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { DropdownState, TopNav, TabData, LinkItem } from './header.interfaces';
import { langCodeList, navDefaultColumns } from '.';
import { DropdownStateConsumer } from './context/dropdown-state.context';
import { AppContextConsumer } from '../../common/context/app.context';
import flatten from 'lodash/flatten';
import {
  Locale,
  defaultLocale,
  stripLocale,
  getLocaleFromPath,
} from '../../../common/util/locales';
import { LocaleLink } from '../../common/links/LocaleLink';
import { Icon } from '../../common/Icon';
import { Hamburger } from '../../common/Hamburger';
import { i18nPhoneNumberMapping } from '../../../common/util/i18n-phone-mapping';
import { Div, Header } from '../../common/Elements';
import { Experiment, Variant } from 'react-optimize';
import UtilityTopNav from './utility-topnav';
import I18nTopnav from './default-topnav';
import logoDataURI from '../../../../static/img/logo-cloudflare-dark';

const defaultURL = {
  origin: '',
  pathname: '/',
  protocol: 'https',
};
type ToggleIndexFunction = (index: number) => void;

function getTopLevelNavData(tabData: TopNav, navSide: string, navColumns = navDefaultColumns) {
  const navData: {
    url: string;
    tabTitle: string;
    type: string;
  }[] = [];
  for (let i = 1; i <= navColumns; i++) {
    const tabColumn = (tabData as any)[`${navSide}Tab${i}`] as TabData[];
    const tabTitle = (tabData as any)[`${navSide}TabTitle${i}`] as string;
    const tabType = (tabData as any)[`${navSide}TabType${i}`] as string;

    if (!tabColumn || !tabTitle) {
      continue;
    }
    navData.push({
      tabTitle,
      url: tabColumn[0].url,
      type: tabType,
    });
  }

  return navData;
}

const TopNavList: FC<{
  tabData: TopNav;
  navSide: 'left' | 'right';
  dropdownState?: DropdownState | null;
  toggleDropdownStateIndex?: ToggleIndexFunction | null;
  locale?: Locale;
  navColumns?: number;
}> = ({
  tabData,
  navSide,
  dropdownState = null,
  toggleDropdownStateIndex = null,
  locale = null,
  navColumns,
}) => {
  const navCols = navColumns ? navColumns : navDefaultColumns;
  const navData = getTopLevelNavData(tabData, navSide, navCols);
  const isEnglish: boolean = !!locale && locale.includes('en');

  return (
    <ul className={`top-nav__item-list top-nav__item-list--${navSide}`}>
      {navData.map((navItem, index) => {
        if (navItem.type === 'dropdown') {
          return (
            <li
              key={`${index}-item`}
              className={`top-nav__item-list__link top-nav__items__desktop nojs-hide ${
                dropdownState && dropdownState[index] ? 'active' : ''
              } ${!isEnglish ? 'top-nav__item-list__link--localized' : ''}`}
              data-tracking-category="mainnav"
              data-tracking-action="click"
              tabIndex={0}
              onClick={e =>
                !!toggleDropdownStateIndex && !!dropdownState
                  ? toggleDropdownStateIndex(index)
                  : null
              }
            >
              {navItem.tabTitle}
            </li>
          );
        } else if (navItem.type === 'link') {
          return (
            <li
              className={`top-nav__item-list__link top-nav__items__desktop ${
                !isEnglish ? 'top-nav__item-list__link--localized' : ''
              }`}
              key={`${index}-navitem`}
            >
              <LocaleLink
                variant="anchor"
                to={navItem.url}
                data-tracking-category="topnav"
                data-tracking-action="click"
                tabIndex={0}
              >
                {navItem.tabTitle}
              </LocaleLink>
            </li>
          );
        } else {
          return (
            <li
              className="flex items-center top-nav__item-list__link top-nav__items__desktop top-nav__item-list__link--no-ul"
              key={`${index}-navitem`}
            >
              <LocaleLink
                style={{ height: '32px' }}
                className={`mw-100 flex justify-center items-center button-under-attack bg-orange0 white ${
                  navItem.url === '#' ? 'js-under-attack-button under-attack-button' : ''
                } ${!isEnglish ? 'button--localized-content' : ''}`}
                variant="anchor"
                to="/under-attack-hotline"
                data-tracking-category="topnav"
                data-tracking-action="click"
                tabIndex={0}
              >
                {navItem.tabTitle}
              </LocaleLink>
            </li>
          );
        }
      })}
    </ul>
  );
};

export type Navi18nBarProps = {
  salesNumber: { displayedNumber: string; number: string };
  topNavData: TopNav;
};
export function persistLangToLocalStorage(event: React.MouseEvent) {
  if (event.target instanceof HTMLAnchorElement) {
    const chosenLang = getLocaleFromPath(event.target.pathname) as Locale;

    localStorage.setItem('langPreference', chosenLang);
  }
}

const Navi18nBar: FC<Navi18nBarProps> = ({ salesNumber, topNavData }) => {
  const [isLangNavOpen, setLangNavOpen] = useState(false);
  const [url, setUrl] = useState({ ...defaultURL });

  useEffect(() => {
    const url = new URL(window.location.href);
    setUrl(url);
  }, []);

  return (
    <AppContextConsumer>
      {({ pathname, locale }) => (
        <Experiment
          id="lTHtngrXRuqV3irUyadBxw"
          loader={
            <I18nTopnav
              locale={locale}
              pathname={pathname}
              salesNumber={salesNumber}
              topNavData={topNavData}
            />
          }
        >
          <Variant id="0">
            <I18nTopnav
              locale={locale}
              pathname={pathname}
              salesNumber={salesNumber}
              topNavData={topNavData}
            />
          </Variant>
          <Variant id="1">
            <UtilityTopNav
              locale={locale}
              pathname={pathname}
              salesNumber={salesNumber}
              topNavData={topNavData}
            />
          </Variant>
        </Experiment>
      )}
    </AppContextConsumer>
  );
};

const NavLink: FC<{ link: LinkItem }> = ({ link }) => {
  if (link.type === 'text') {
    return (
      <div
        className={`nav-dropdown-header ${
          link.color && link.color === 'orange' ? 'header-orange' : 'header-black'
        }`}
        dangerouslySetInnerHTML={{ __html: link.title }}
      />
    );
  } else if (link.type === 'phoneNumber') {
    return (
      <a data-i18n-phonenumber href="tel:+16503198930">
        +1 650 319 8930
      </a>
    );
  } else if (link.type === 'link' || link.type === 'underattack ') {
    return (
      <span className="nav-dropdown-link">
        <LocaleLink variant="anchor" to={link.url as string}>
          {link.title}
        </LocaleLink>
      </span>
    );
  }
  return null;
};

const DropdownMenus: FC<{
  tabData: TopNav;
  dropdownState: DropdownState;
  navColumns?: number;
}> = props => {
  let dropdownData: {
    url: string;
    tabTitle: string;
    type: string;
    tabColumns: TabData[];
  }[] = [];
  const { tabData, dropdownState, navColumns } = props;
  //localize dash links on localized pages
  if (tabData.locale != 'en-US' && !tabData.rightTab1[0].url.includes('?lang=')) {
    tabData.rightTab1[0].url = tabData.rightTab1[0].url + '?lang=' + tabData.locale?.toLowerCase();
    tabData.rightTab2[0].url = tabData.rightTab2[0].url + '?lang=' + tabData.locale?.toLowerCase();
  }
  const navCols = !!navColumns ? navColumns : navDefaultColumns;
  for (let i = 1; i <= navCols; i++) {
    const tabColumns = (tabData as any)[`leftTab${i}`] as TabData[];
    const tabTitle = (tabData as any)[`leftTabTitle${i}`] as string;
    const tabType = (tabData as any)[`leftTabType${i}`] as string;

    if (!tabColumns || !tabTitle) {
      continue;
    }
    dropdownData.push({
      tabTitle,
      url: tabColumns[0].url,
      type: tabType,
      tabColumns: tabColumns,
    });
  }
  return (
    <div className="desktop-nav MRK-9222-top-nav nav-dropdown">
      {dropdownData.map((dropdown, idx) => {
        if (dropdown.type === 'dropdown') {
          return (
            <div
              className={`desktop-nav__container ${dropdownState[idx] ? 'open' : ''}`}
              key={`${idx}-dropdownmenu`}
            >
              <div className="nav-dropdown-container">
                {dropdown.tabColumns.map((tabColumn, tabCIndex) => {
                  return (
                    <div
                      key={`${idx}-${tabCIndex}`}
                      className={`dropdown-column ${
                        dropdown.tabColumns.length === 5 ? 'maxwidth-fifth' : ''
                      }`}
                    >
                      {tabColumn.type === 'dropdown'
                        ? tabColumn.links.map(link => (
                            <NavLink key={`${link.title}-tabColumn-${idx}`} link={link} />
                          ))
                        : null}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

const MobileDropdown: FC<{ data: TopNav; mobileNavActive: Array<boolean> }> = ({
  data,
  mobileNavActive,
}) => {
  const tabData: {
    links: LinkItem[];
    url: string;
    title: string;
    type: string;
  }[] = [];
  for (let i = 1; i <= navDefaultColumns; i++) {
    const tabColumns = (data as any)[`leftTab${i}`] as TabData[];
    const tabTitle = (data as any)[`leftTabTitle${i}`] as string;
    const tabType = (data as any)[`leftTabType${i}`] as string;
    if (!tabColumns || !tabTitle) {
      continue;
    }
    tabData.push({
      links: flatten(tabColumns.filter(c => c.type === 'dropdown').map(c => c.links)),
      url: tabColumns[0].url,
      title: tabTitle,
      type: tabType,
    });
  }
  for (let i = 1; i <= navDefaultColumns; i++) {
    const tabColumns = (data as any)[`rightTab${i}`] as TabData[];
    const tabTitle = (data as any)[`rightTabTitle${i}`] as string;
    const tabType = (data as any)[`rightTabType${i}`] as string;
    if (!tabColumns || !tabTitle) {
      continue;
    }
    tabData.push({
      links: flatten(tabColumns.filter(c => c.type === 'dropdown').map(c => c.links)),
      url: tabColumns[0].url,
      title: tabTitle,
      type: tabType,
    });
  }

  for (let i = 2; i <= 3; i++) {
    const utilityDataColumn = (data as any)[`utilityTab${i}`];
    const tabTitle = (data as any)[`utilityTabTitle${i}`] as string;

    if (!utilityDataColumn || !tabTitle) {
      continue;
    }
    tabData.push({
      links: utilityDataColumn.links,
      url: utilityDataColumn.links[0].url,
      title: tabTitle,
      type: utilityDataColumn['type'],
    });
  }

  return (
    <>
      {tabData.map((tab, index) => {
        if (tab.type === 'dropdown') {
          return (
            <AccordionItem
              style={{ width: '100%' }}
              uuid={(index + 1) as any}
              key={`${index}-mobile-topnav`}
            >
              <li className="mobile-nav__list__item">
                <AccordionItemHeading style={{ width: '100%' }}>
                  <AccordionItemButton className="mobile-sub-nav__title" data-submenu="products">
                    <span className="mobile-sub-nav__title__text">{tab.title}</span>
                    <span style={{ float: 'right' }}>
                      <Icon type={mobileNavActive[index + 1] ? 'caret-down' : 'caret-right'} />
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </li>
              <AccordionItemPanel className="mobile-submenu-languageMobile">
                <ul className="mobile-sub-nav">
                  {tab.links.map((link, i) => (
                    <NavLink key={`mobile-${link.title}-${i}`} link={link} />
                  ))}
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
          );
        } else if (tab.type === 'link') {
          return (
            <li className="mobile-nav__list__item" key={`${index}-mobile-topnav`}>
              <LocaleLink
                variant="anchor"
                to={tab.url}
                data-tracking-action="click"
                tabIndex={0}
                className="mobile-sub-nav__title"
              >
                <span className="mobile-sub-nav__title">{tab.title}</span>
              </LocaleLink>
            </li>
          );
        } else {
          return (
            <li
              className="mobile-nav__list__item mobile-nav__button__under-attack"
              key={`${index}-navitem`}
            >
              <span
                className={`nav-dropdown-link  orange0 js-under-attack-button under-attack-button button--localized-content`}
              >
                <LocaleLink
                  to="/under-attack-hotline/"
                  data-tracking-category="topnav"
                  data-tracking-action="click"
                  data-tracking-label="under_attack"
                >
                  {tab.title}
                </LocaleLink>
              </span>
            </li>
          );
        }
      })}
    </>
  );
};

export interface HeaderProps {
  locale?: Locale;
  topNavData: TopNav;
  navColumns?: number;
  countryCode: string;
  customHeaderLogoUrl?: string | null;
}

const NavHeader: FC<HeaderProps> = ({
  children,
  topNavData,
  locale,
  navColumns,
  countryCode,
  customHeaderLogoUrl,
}) => {
  const navCols = navColumns ? navColumns : navDefaultColumns;
  const defaultArrowState = new Array(navCols + 1).fill(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [mobileNavActive, setmobileNavActive] = useState(defaultArrowState);
  const isEnglish: boolean = !!locale && locale.includes('en');

  const setArrowState = (index: number) => {
    if (index === undefined) {
      setmobileNavActive(defaultArrowState);
    }
    const toggledArray = [...defaultArrowState];
    toggledArray[index] = true;
    setmobileNavActive(toggledArray);
  };

  const salesPhoneNumber = i18nPhoneNumberMapping[countryCode] || i18nPhoneNumberMapping['i18n'];

  return (
    <AppContextConsumer>
      {({ locale, pathname }) => (
        <Media
          queries={{
            small: '(max-width: 998px)',
            large: '(min-width: 999px)',
          }}
          defaultMatches={{ large: true }}
        >
          {matches => (
            <>
              {matches.large ? (
                <DropdownStateConsumer>
                  {({ dropdownState, toggleDropdownStateIndex }) => (
                    <Header position="sticky" className="cms-topnav topnav top-nav js-top-nav">
                      <Navi18nBar salesNumber={salesPhoneNumber} topNavData={topNavData} />
                      <nav className="top-nav__items top-nav__items--align-content">
                        <LocaleLink variant="anchor" to="/">
                          <img
                            className={`top-nav__logo top-nav__logo--dark ${
                              !isEnglish ? 'top-nav__logo--localized' : ''
                            }`}
                            src={customHeaderLogoUrl ? customHeaderLogoUrl : logoDataURI}
                            style={customHeaderLogoUrl ? { width: '180px' } : undefined}
                            alt="Cloudflare"
                          />
                        </LocaleLink>
                        <TopNavList
                          tabData={topNavData}
                          navSide="left"
                          navColumns={navCols}
                          dropdownState={dropdownState as DropdownState}
                          toggleDropdownStateIndex={toggleDropdownStateIndex as ToggleIndexFunction}
                          locale={locale}
                        />
                        <TopNavList tabData={topNavData} navSide="right" />
                      </nav>
                      <DropdownMenus
                        tabData={topNavData}
                        dropdownState={dropdownState as DropdownState}
                        navColumns={navCols}
                      />
                    </Header>
                  )}
                </DropdownStateConsumer>
              ) : (
                <Header
                  position={isHamburgerOpen ? 'fixed' : 'sticky'}
                  className={'cms-topnav topnav top-nav js-top-nav'}
                >
                  <nav className="top-nav__items top-nav__items--align-content">
                    <LocaleLink variant="anchor" to="/">
                      <img
                        className="top-nav__logo top-nav__logo--dark"
                        src={customHeaderLogoUrl ? customHeaderLogoUrl : logoDataURI}
                        alt="Cloudflare"
                        style={customHeaderLogoUrl ? { width: '180px' } : undefined}
                      />
                    </LocaleLink>
                    <Div display="flex" marginLeft="auto" marginRight={1}>
                      <Hamburger
                        isActive={isHamburgerOpen}
                        onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
                      />
                    </Div>
                  </nav>
                  <div
                    style={{ height: '100%', overflowY: 'scroll', paddingBottom: '150px' }}
                    className={'mobile-nav nav-dropdown' + (isHamburgerOpen ? ' open' : '')}
                  >
                    <Accordion
                      allowZeroExpanded
                      onChange={idxs => idxs.forEach(idx => setArrowState(idx as any))}
                    >
                      <ul className="mobile-nav__list">
                        <MobileDropdown data={topNavData} mobileNavActive={mobileNavActive} />

                        <AccordionItem style={{ width: '100%' }} uuid={0 as any}>
                          <li className="mobile-nav__list__item mobile-language-picker">
                            <AccordionItemHeading style={{ width: '100%' }}>
                              <AccordionItemButton
                                className="mobile-sub-nav__title"
                                data-submenu="languageMobile"
                              >
                                <span className="mobile-sub-nav__title__text">
                                  {langCodeList[locale.toLocaleLowerCase()].value}
                                </span>
                                <span style={{ float: 'right' }}>
                                  <Icon type={mobileNavActive[0] ? 'caret-down' : 'caret-right'} />
                                </span>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          </li>
                          <AccordionItemPanel className="mobile-submenu-languageMobile">
                            <ul className="mobile-sub-nav">
                              {Object.keys(langCodeList).map(lang => {
                                return (
                                  <li
                                    key={`mobile-nav-item-${lang}`}
                                    className="desktop-nav__sub-menu__list__item"
                                    onClick={e => {
                                      persistLangToLocalStorage(e);
                                    }}
                                  >
                                    <a
                                      href={
                                        lang === defaultLocale.toLocaleLowerCase()
                                          ? `${stripLocale(pathname)}`
                                          : `/${lang}${stripLocale(pathname)}`
                                      }
                                      data-tracking-category="topnav"
                                      data-tracking-action="click"
                                      data-tracking-label="en"
                                    >
                                      {langCodeList[lang].dropdownTitle}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </ul>
                    </Accordion>
                  </div>
                </Header>
              )}
            </>
          )}
        </Media>
      )}
    </AppContextConsumer>
  );
};

export default NavHeader;
