import React from 'react';
import Media from 'react-media';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';
import { Nav } from '../../../common/Elements';
import { StyleSystemProps } from '../../../common/Elements/Elements';
import { LocaleLink } from '../../../common/links/LocaleLink';
import Text from '../../../common/Text/Text';
import { trackNavigationData } from '../utils/navigation.tracking';

interface Props {
  rightNavData: ContentfulNavigation;
}

export const RightNav: React.FC<Props> = ({ rightNavData }) => {
  return (
    <Media
      queries={{
        large: '(min-width: 1120px)',
      }}
      defaultMatches={{ large: true }}
    >
      {(matches) => {
        const ctaProps: Pick<
          StyleSystemProps,
          'paddingHorizontal' | 'paddingVertical' | 'backgroundColor' | 'border'
        > = {
          paddingHorizontal: matches.large ? 4 : 2,
          paddingVertical: 1,
          backgroundColor: 'orange0',
          border: 'none',
        };
        return (
          <Nav display="flex" flexWrap="wrap" alignItems="center" width="100%" justifyContent="end">
            {rightNavData.navigationItems?.map((item, index) => (
              <LocaleLink
                tabIndex={0}
                marginRight={
                  rightNavData.navigationItems?.length - 1 === index ? 0 : matches.large ? 4 : 2
                }
                variant="anchor"
                display="block"
                className="newNav-link"
                key={item.contentfulId}
                to={item.mainLink.url}
                {...(item.mainLink.specialLinkType === 'CTA' ? ctaProps : {})}
                onClick={() =>
                  trackNavigationData({
                    element: item.mainLink.specialLinkType === 'CTA' ? 'button' : 'link',
                    label: `utilitynav|${item.mainLink.title}:[${item.mainLink.url}]`,
                  })
                }
              >
                <Text
                  tag="Span"
                  variant="body2-bold"
                  color={item.mainLink.specialLinkType === 'CTA' ? 'white' : 'blue1'}
                >
                  {item.mainLink.title}
                </Text>
              </LocaleLink>
            ))}
          </Nav>
        );
      }}
    </Media>
  );
};
