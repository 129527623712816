import React from 'react';
import Media from 'react-media';
import { ContentfulNavigationItem } from '../../../../../contentful/content-types/navigationitem';
import { CFChevronRight } from '../../../../common/CFIcons/CFChevronRight';
import { Button, Div, Span } from '../../../../common/Elements';
import { LocaleLink } from '../../../../common/links/LocaleLink';
import Text from '../../../../common/Text/Text';
import { useHeaderContext } from '../../header.context';
import { TAB_TYPES } from '../../reducers/tabmenu.reducer';
import { useTabContext } from '../../tabs.context';
import { trackNavigationData } from '../../utils/navigation.tracking';
import { MenuMain } from './MenuMain';

interface Props {
  tab: ContentfulNavigationItem;
  lastItem?: boolean;
}

interface MenuTabItemContentProps extends Props {
  isMobile: boolean;
}

export const MenuTabItemContent: React.FC<MenuTabItemContentProps> = ({
  tab,
  lastItem,
  isMobile,
}) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const { tab: tabState, dispatch } = useTabContext();
  const { modal } = useHeaderContext();

  const isTabMenu = tab.navigationItems?.length || tab?.navigationLinks?.length;

  const isActive = tabState.tabProps.selectedTab === tab.contentfulId;

  const activeMobileMenuPadding = isActive ? 3 : 2;

  const handleTabClick = (tab: ContentfulNavigationItem) => {
    if (isTabMenu) {
      if (isActive) {
        if (isMobile) {
          dispatch({
            type: TAB_TYPES.RESET,
          });
        }
      } else {
        dispatch({
          type: TAB_TYPES.SWITCH,
          payload: {
            selectedTab: tab.contentfulId,
            tabData: tab,
          },
        });
      }
    }
    trackNavigationData({
      element: 'button',
      label: `mainnav|${modal?.modalProps?.modalData?.name}|mainnav-sidebar_${
        tab.name || tab.mainLink.title
      }`,
    });
  };

  if (isTabMenu) {
    return (
      <Div border={lastItem ? 'none' : 'bottom'} borderColor="gray2">
        <Button
          display="flex"
          justifyContent="between"
          textAlign="left"
          flexWrap="wrap"
          width="100%"
          backgroundColor="transparent"
          paddingHorizontal={0}
          paddingVertical={2}
          className="pointer newNav-button"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => handleTabClick(tab)}
        >
          <Span display="flex" width="100%" alignItems="center" justifyContent="between">
            <Text
              tag="Span"
              color={hovered ? 'bluehover' : isActive ? 'blue1' : 'black'}
              variant="body2-bold"
            >
              {tab.name}
            </Text>
            <Span display={['flex', 'none', 'flex', 'none']}>
              <CFChevronRight
                className={isActive ? 'rotate-270' : 'rotate-90'}
                size={16}
                color={hovered ? 'bluehover' : isActive ? 'blue1' : 'black'}
              />
            </Span>
            <Span display={['none', 'none', 'none', 'flex']}>
              <CFChevronRight
                size={16}
                color={hovered ? 'bluehover' : isActive ? 'blue1' : 'black'}
              />
            </Span>
          </Span>
        </Button>
        {isActive && (
          <Div width="100%" display={['block', 'none', 'block', 'none']} marginVertical={2}>
            <MenuMain />
          </Div>
        )}
      </Div>
    );
  }
  return (
    <Div
      display="flex"
      justifyContent="between"
      border={lastItem ? 'none' : 'bottom'}
      borderColor="gray2"
      paddingVertical={[
        activeMobileMenuPadding,
        activeMobileMenuPadding,
        activeMobileMenuPadding,
        2,
      ]}
      className="pointer"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <LocaleLink
        openInNewTab={!!tab.mainLink.openInNewWindow}
        width="100%"
        className="newNav-link"
        display="block"
        variant={tab.mainLink.isExternalUrl ? 'anchor' : 'link'}
        to={tab.mainLink?.url || ''}
        onClick={() =>
          trackNavigationData({
            element: 'button',
            label: `mainnav|${modal?.modalProps?.modalData?.name}|mainnav-sidebar_${
              tab.name || tab.mainLink.title
            }:[${tab.mainLink?.url}]`,
          })
        }
      >
        <Text
          color={hovered ? 'bluehover' : isActive ? 'blue1' : 'black'}
          tag="Span"
          variant="body2-bold"
        >
          {tab.name || tab.mainLink.title}
        </Text>
      </LocaleLink>
    </Div>
  );
};

export const MenuTabItem: React.FC<Props> = ({ tab, lastItem }) => {
  return (
    <Media
      queries={{
        small: '(max-width: 991px)',
        large: '(min-width: 992px)',
      }}
      defaultMatches={{ large: true }}
    >
      {(matches) => <MenuTabItemContent isMobile={!matches.large} tab={tab} lastItem={lastItem} />}
    </Media>
  );
};
