import React from 'react';
// import { useStaticStrings } from '../../../../common/hooks/useStaticStrings';
import { i18nPhoneNumberMapping } from '../../../../common/util/i18n-phone-mapping';
import { MaybeRedwood } from '../../../../config';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';
import { ButtonCTA } from '../../../common/buttons/Button';
import { A, Div, Nav } from '../../../common/Elements';
import { LocaleLink } from '../../../common/links/LocaleLink';
import Text from '../../../common/Text/Text';
import { useHeaderContext } from '../header.context';
import { trackNavigationData } from '../utils/navigation.tracking';
import { MenuItem } from './NavMenuItem';

interface Props {
  navData: ContentfulNavigation;
  utilityNav: ContentfulNavigation;
  rightNavData: ContentfulNavigation;
}

export const NavMenu: React.FC<Props> = ({ navData, utilityNav, rightNavData }) => {
  const { modal } = useHeaderContext();
  const [countryCode, setCountryCode] = React.useState('US');
  // const staticStrings = useStaticStrings();

  const modalData = modal.modalProps.modalData;

  const showMenuOnMobile = modalData ? 'none' : 'flex';

  React.useEffect(() => {
    const redwood = (window as MaybeRedwood).redwood;
    setCountryCode(redwood?.country || countryCode);
  }, []);

  const salesPhoneNumber = i18nPhoneNumberMapping[countryCode] || i18nPhoneNumberMapping['i18n'];

  const supportNav = utilityNav?.navigationItems?.find(
    (nav) => nav.contentfulId === '5XqIIHouIN3SqoeS8eIQL8'
  );

  const signUpNavItem = rightNavData?.navigationItems?.find(
    (nav) => nav.contentfulId === '7rbuvTQnwySxPNN0BVU8fv'
  );

  const underAttackNavItem = rightNavData?.navigationItems?.find(
    (nav) => nav.contentfulId === '5AyH2V4deDvjZUiKrgS90P'
  );

  return (
    <>
      <Nav
        display={[showMenuOnMobile, showMenuOnMobile, showMenuOnMobile, 'flex']}
        flexDirection={['column', 'column', 'column', 'row']}
        paddingVertical={[3, 3, 3, 0]}
        width="100%"
        id="navmenu"
        alignItems={['start', 'start', 'start', 'stretch']}
        flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
        justifyContent="start"
      >
        {navData.navigationItems.map((data) => (
          <MenuItem data={data} key={data.contentfulId} />
        ))}
        {/* Extra Menu Items for mobile */}
        {supportNav && (
          <Div
            justifyContent="between"
            alignItems="center"
            width="100%"
            display={['flex', 'none', 'flex', 'none']}
          >
            <MenuItem data={supportNav} key={supportNav.contentfulId} />
          </Div>
        )}
        <Div
          paddingVertical={2}
          border="bottom"
          borderColor="gray2"
          justifyContent="between"
          alignItems="center"
          width="100%"
          display={['flex', 'none', 'flex', 'none']}
        >
          <LocaleLink
            display="block"
            className="newNav-link"
            tabIndex={0}
            to={underAttackNavItem?.mainLink.url || ''}
            onClick={() =>
              trackNavigationData({
                element: 'button',
                label: `mainnav|${underAttackNavItem?.name}:[${underAttackNavItem?.mainLink.url}]`,
              })
            }
          >
            <Text color="orange0" tag="Span" variant="body2-bold">
              {underAttackNavItem?.name}
            </Text>
          </LocaleLink>
        </Div>
        <Div
          paddingVertical={2}
          justifyContent="between"
          alignItems="center"
          width="100%"
          display={['flex', 'none', 'flex', 'none']}
        >
          <LocaleLink
            display="block"
            data-i18n-phonenumber=""
            tabIndex={0}
            className="newNav-link"
            onClick={() =>
              trackNavigationData({
                element: 'button',
                label: `mainnav|${salesPhoneNumber.displayedNumber}:[tel:${salesPhoneNumber.number}]`,
              })
            }
            to={`tel:${salesPhoneNumber.number}`}
          >
            <Text color="gray0" tag="Span" variant="body2">
              Sales: {salesPhoneNumber.displayedNumber}
            </Text>
          </LocaleLink>
        </Div>
        {/* Extra Menu Items for mobile */}
        {/* Bottom Menu Items for mobile */}
        <Div marginTop={7} width="100%" display={['block', 'none', 'block', 'none']}>
          <ButtonCTA
            href={signUpNavItem?.mainLink.url || ''}
            marginBottom={2}
            className="newNav-link"
            buttonStyle="auto"
            buttonColor="orange"
            onClick={() =>
              trackNavigationData({
                element: 'button',
                label: `mainnav|${signUpNavItem?.name}:[${signUpNavItem?.mainLink.url}]`,
              })
            }
          >
            {signUpNavItem?.name}
          </ButtonCTA>
          <ButtonCTA
            href="/plans/enterprise/contact"
            buttonStyle="auto"
            className="newNav-link"
            marginBottom={2}
            buttonColor="orange-outline"
            onClick={() =>
              trackNavigationData({
                element: 'button',
                label: `mainnav|Contact Sales:[/plans/enterprise/contact]`,
              })
            }
          >
            Contact Sales
          </ButtonCTA>
        </Div>
        {/* Bottom Menu Items for mobile */}
      </Nav>
    </>
  );
};
