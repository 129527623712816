import Link from 'gatsby-link';
import React from 'react';
import { useLocale } from '../../../../../common/hooks/useLocale';
import {
  defaultLocale,
  getLocaleFromPath,
  Locale,
  stripLocale,
} from '../../../../../common/util/locales';
import { CFInternetGlobe } from '../../../../common/CFIcons/CFInternetGlobe';
import Dropdown from '../../../../common/Dropdown';
import { Button, Li, Span, Ul } from '../../../../common/Elements';
import { Icon } from '../../../../common/Icon';
import Text from '../../../../common/Text/Text';
import { langCodeList } from '../../../header/constants/langcodelist';
import { useLocation } from '@reach/router';
import { trackNavigationData } from '../../utils/navigation.tracking';

interface Props {}

export function persistLangToLocalStorage(event: React.MouseEvent) {
  if (event.target instanceof HTMLAnchorElement) {
    const chosenLang = getLocaleFromPath(event.target.pathname) as Locale;

    localStorage.setItem('langPreference', chosenLang);
  }
}

const handleClick = (event: React.MouseEvent, lang: string, url: string) => {
  persistLangToLocalStorage(event);
  trackNavigationData({
    element: 'link',
    label: `utilitynav|langdropdown-${lang}:[${url}]`,
  });
};

export const LocaleDropdown: React.FC<Props> = (props: Props) => {
  const locale = useLocale();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Dropdown
      renderDropdownContent={() => (
        <Ul className="list" margin={0} padding={0}>
          {Object.keys(langCodeList).map((lang, index) => {
            const url =
              lang === defaultLocale.toLocaleLowerCase()
                ? `${stripLocale(pathname)}`
                : `/${lang}${stripLocale(pathname)}`;
            return (
              <Li marginBottom={1} key={`langdropdown-${lang}`}>
                <Link onClick={(e) => handleClick(e, lang, url)} to={url} className="newNav-link">
                  <Text
                    hovered={{ fontWeight: 7 }}
                    fontWeight={locale.toLocaleLowerCase() === lang ? 7 : 4}
                    color="black"
                    variant="body3"
                  >
                    {langCodeList[lang].dropdownTitle}
                  </Text>
                </Link>
              </Li>
            );
          })}
        </Ul>
      )}
      renderToggle={(isOpen, toggle) => (
        <Button
          className="pointer newNav-button"
          marginLeft={1}
          display="flex"
          alignItems="center"
          data-tooltipped=""
          onClick={() => {
            toggle();
            trackNavigationData({
              element: 'dropdown',
              label: `utilitynav|langdropdown`,
            });
          }}
          role="button"
          tabIndex={0}
          backgroundColor="transparent"
          padding={0}
        >
          <CFInternetGlobe color="gray0" size={16} />
          <Span className={`${isOpen ? 'rotate-180' : ''}`} lineHeight={0} marginLeft="4px">
            <Icon type="nav-caret" />
          </Span>
        </Button>
      )}
    />
  );
};
