import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { langCodeList, Navi18nBarProps, persistLangToLocalStorage } from '.';
import { defaultLocale, stripLocale } from '../../../common/util/locales';
import { Span } from '../../common/Elements';
import { LocaleLink } from '../../common/links/LocaleLink';
import Dropdown from '../../common/Dropdown';
import { useTranslations } from '../../../common/hooks/useTranslations';

export interface UtilityTopNavProps extends Navi18nBarProps {
  locale: string;
  pathname: string;
}
const UtilityTopNav: React.FC<UtilityTopNavProps> = ({
  salesNumber,
  topNavData,
  pathname,
  locale,
}) => {
  const translate = useTranslations();
  return (
    <nav className="top-nav__upper-bar top-nav__items">
      <div className="top-nav__item-list top-nav__item-list--right">
        <span>
          <LocaleLink to="/plans/enterprise/contact">{translate('sales_with_colon')} </LocaleLink>
        </span>
        <a data-i18n-phonenumber="" href={`tel:${salesNumber.number}`}>
          {salesNumber.displayedNumber}
        </a>
        <noscript>
          <a href={`tel:${salesNumber.number}`}> {salesNumber.displayedNumber}</a>
        </noscript>
        <span className="header-section-divider">|</span>

        <>
          {topNavData.utilityTab2 && (
            <Dropdown
              renderToggle={(isOpen, toggle) => (
                <a
                  className="header-language-picker js-header-language-picker"
                  data-tooltipped=""
                  data-tracking-category="mainnav"
                  data-tracking-action="click"
                  data-tracking-label={topNavData.utilityTabTitle2}
                  onClick={toggle}
                >
                  <Span className="semibold">{topNavData.utilityTabTitle2} </Span>
                  <span>
                    <svg
                      width="8"
                      height="6"
                      viewBox="0 0 8 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 1.15819L6.84183 2.21065e-05L4.00053 2.84207L1.15816 0L0 1.1576L4.00053 5.15783L8 1.15819Z"
                        fill="#747474"
                      />
                    </svg>
                  </span>
                </a>
              )}
              renderDropdownContent={(isOpen) => (
                <div className="js-header-language-picker-dropdown">
                  <ul className="marketing-ul marketing-ul--no-bullets marketing-ul--no-margins-li header-language-picker-dropdown">
                    {topNavData.utilityTab2?.links?.map((link, index) => {
                      return (
                        <li
                          key={`langdropdown-${link.title}`}
                          className={link.extraClass ? 'merchandise' : ''}
                        >
                          <Link
                            to={link.url || ''}
                            data-tracking-category="topnav"
                            data-tracking-action="click"
                            data-tracking-label={locale.split('-')[0]}
                            className="dropdown__link"
                          >
                            {link.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            ></Dropdown>
          )}

          {topNavData.utilityTab3 && (
            <>
              <span className="header-section-divider">|</span>
              <Dropdown
                renderToggle={(isOpen, toggle) => (
                  <a
                    className="header-language-picker js-header-language-picker"
                    data-tooltipped=""
                    data-tracking-category="mainnav"
                    data-tracking-action="click"
                    data-tracking-label={topNavData.utilityTabTitle3}
                    onClick={toggle}
                  >
                    <Span className="semibold">{topNavData.utilityTabTitle3} </Span>
                    <span>
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 1.15819L6.84183 2.21065e-05L4.00053 2.84207L1.15816 0L0 1.1576L4.00053 5.15783L8 1.15819Z"
                          fill="#747474"
                        />
                      </svg>
                    </span>
                  </a>
                )}
                renderDropdownContent={(isOpen) => (
                  <div className="js-header-language-picker-dropdown">
                    <ul className="marketing-ul marketing-ul--no-bullets marketing-ul--no-margins-li header-language-picker-dropdown">
                      {topNavData.utilityTab3?.links?.map((link, index) => {
                        return (
                          <li
                            key={`langdropdown-${link.title}`}
                            className={link.extraClass ? 'merchandise' : ''}
                          >
                            <Link
                              to={link.url || ''}
                              data-tracking-category="topnav"
                              data-tracking-action="click"
                              data-tracking-label={link.title.toLowerCase()}
                              className="dropdown__link"
                            >
                              {link.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              ></Dropdown>
              <span className="header-section-divider">|</span>
            </>
          )}
        </>

        <Dropdown
          renderToggle={(isOpen, toggle) => (
            <a
              className="header-language-picker js-header-language-picker flex items-center"
              data-tooltipped=""
              onClick={toggle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="none"
                viewBox="0 0 10.13 10.13"
                className="mr1"
              >
                <g data-name="Layer 2">
                  <g data-name="Layer 1">
                    <path
                      d="M2.37 5.42H.52a4.53 4.53 0 00.63 2h1.52a8.59 8.59 0 01-.3-2zm-.89 2.47a4.57 4.57 0 002.23 1.53 4.34 4.34 0 01-.91-1.53zm1.16-5.06H1.09a4.53 4.53 0 00-.59 2.1h1.86a8.75 8.75 0 01.28-2.1zm.13-.48A4.46 4.46 0 013.71.71a4.57 4.57 0 00-2.32 1.64zm4.55 5.54a4.34 4.34 0 01-.91 1.53 4.57 4.57 0 002.24-1.53zm.14-.48H9a4.53 4.53 0 00.63-2H7.76a8.56 8.56 0 01-.3 2zM5.06.52c-.71 0-1.35.73-1.77 1.83h3.54C6.42 1.25 5.78.52 5.06.52zm2.43 2.31a8.72 8.72 0 01.28 2.1h1.86A4.53 4.53 0 009 2.83zm1.25-.48A4.58 4.58 0 006.42.71a4.46 4.46 0 01.94 1.64zM5.06 9.61c.69 0 1.31-.69 1.72-1.72H3.34c.42 1.03 1.04 1.72 1.72 1.72zM2.84 4.93h4.45A8.12 8.12 0 007 2.83H3.14a8.12 8.12 0 00-.3 2.1zm.33 2.48H7a8 8 0 00.32-2H2.85a8 8 0 00.32 2z"
                      fill="none"
                    />
                    <path
                      d="M5.06 0a5.06 5.06 0 105.07 5.06A5.07 5.07 0 005.06 0zM9 7.41H7.46a8.56 8.56 0 00.3-2h1.85a4.53 4.53 0 01-.61 2zm-2.57 2a4.34 4.34 0 00.91-1.53h1.31a4.57 4.57 0 01-2.23 1.54zM1.48 7.89h1.33a4.34 4.34 0 00.91 1.53 4.57 4.57 0 01-2.24-1.53zm-1-2.47h1.89a8.59 8.59 0 00.3 2H1.15a4.53 4.53 0 01-.63-2zm.57-2.58h1.59a8.75 8.75 0 00-.28 2.1H.5a4.53 4.53 0 01.59-2.11zM3.71.71a4.46 4.46 0 00-.94 1.64H1.39A4.57 4.57 0 013.71.71zm5 1.64H7.35A4.46 4.46 0 006.42.71a4.58 4.58 0 012.32 1.64zM7.29 4.93H2.84a8.12 8.12 0 01.3-2.1H7a8.12 8.12 0 01.29 2.1zM7 7.41H3.17a8 8 0 01-.32-2h4.43a8 8 0 01-.28 2zm-.17.48c-.41 1-1 1.72-1.72 1.72s-1.35-.69-1.77-1.72zM3.3 2.35C3.71 1.25 4.35.52 5.06.52s1.35.73 1.77 1.83zm4.47 2.58a8.72 8.72 0 00-.28-2.1H9a4.53 4.53 0 01.59 2.1z"
                      fill="gray"
                    />
                  </g>
                </g>
              </svg>
              {langCodeList[locale.toLocaleLowerCase()].value}{' '}
              <span className="ml1">
                <svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 1.15819L6.84183 2.21065e-05L4.00053 2.84207L1.15816 0L0 1.1576L4.00053 5.15783L8 1.15819Z"
                    fill="#747474"
                  />
                </svg>
              </span>
            </a>
          )}
          renderDropdownContent={(isOpen) => (
            <div
              id="js-header-language-picker-dropdown"
              className="js-header-language-picker-dropdown"
            >
              <ul className="marketing-ul marketing-ul--no-bullets marketing-ul--no-margins-li header-language-picker-dropdown">
                {Object.keys(langCodeList).map((lang, index) => {
                  return (
                    <li
                      key={`langdropdown-${lang}`}
                      className={classNames({
                        'header-language-picker-dropdown__active':
                          lang === locale.toLocaleLowerCase(),
                      })}
                      onClick={persistLangToLocalStorage}
                    >
                      <Link
                        to={
                          lang === defaultLocale.toLocaleLowerCase()
                            ? `${stripLocale(pathname)}`
                            : `/${lang}${stripLocale(pathname)}`
                        }
                        data-tracking-category="topnav"
                        data-tracking-action="click"
                        data-tracking-label={locale.split('-')[0]}
                        className="dropdown__link"
                      >
                        {langCodeList[lang].dropdownTitle}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        ></Dropdown>
      </div>
    </nav>
  );
};

export default UtilityTopNav;
