import React from 'react';
import Media from 'react-media';
import { Div, Row, Col } from '../../../../common/Elements';
import { useTabContext } from '../../tabs.context';
import { MenuItem } from './MenuItem';
import { MenuItemGroup } from './MenuItemGroup';

interface Props {}

export const MenuMain: React.FC<Props> = (props: Props) => {
  const { tab } = useTabContext();

  const hasModalMerchandize = tab.tabProps.tabData?.navigationLinks?.filter(
    (link) => link.specialLinkType === 'MERCHANDIZE'
  )?.length;

  const tabLinks = tab.tabProps.tabData?.navigationLinks?.filter((link) => !link.specialLinkType);
  const tabGroups = tab.tabProps.tabData?.navigationItems;

  return (
    <Media
      queries={{
        small: '(max-width: 991px)',
        large: '(min-width: 992px)',
      }}
      defaultMatches={{ large: true }}
    >
      {(matches) => (
        <Row width="100%">
          <Col lg={12}>
            <Row>
              <Div
                display="flex"
                flexDirection="column"
                alignContent="start"
                flexWrap="wrap"
                width="100%"
                height="auto"
                style={{
                  maxHeight: matches.large ? 'calc(100vh - 180px)' : '',
                  minHeight: matches.large ? '640px' : '',
                }}
              >
                {tabLinks?.map((link) => (
                  <MenuItem
                    hasModalMerchandize={!!hasModalMerchandize}
                    link={link}
                    key={link.contentfulId}
                  />
                ))}
                {tabGroups?.map((group) => (
                  <MenuItemGroup
                    hasModalMerchandize={!!hasModalMerchandize}
                    group={group}
                    key={group.contentfulId}
                  />
                ))}
              </Div>
            </Row>
          </Col>
        </Row>
      )}
    </Media>
  );
};
