import React from 'react';
import { locales, Locale, stripLocale } from '../../../common/util/locales';
import { pathJoin } from '../../../common/util/path';

const basePath = 'https://www.cloudflare.com';
export function buildURL(locale: Locale, pathname: string, baseURL = basePath) {
  const strippedPath = stripLocale(pathname);

  if (locale.toLocaleLowerCase() === 'en-us') {
    return pathJoin(baseURL, strippedPath, '/');
  }

  return pathJoin(baseURL, locale.toLowerCase(), strippedPath, '/');
}

export const HrefLangs = (
  locale: Locale,
  pathname: string,
  baseURL = basePath,
  localeList = locales
) => {
  return [
    ...localeList
      .filter((l) => !!l)
      .map((l) => {
        return (
          <link
            key={l}
            rel="alternate"
            hrefLang={l.toLocaleLowerCase()}
            href={buildURL(l, pathname, baseURL)}
          />
        );
      }),
    pathname === '/home' ? (
      <link key={pathname} rel="canonical" href={basePath} />
    ) : (
      <link key={pathname} rel="canonical" href={buildURL(locale, pathname, baseURL)} />
    ),
  ];
};
